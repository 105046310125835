.door-wrapper{
  display: flex;
  justify-content: space-between;
  .door-title-img-wrapper{
    display: flex;
    gap: 16px;
    align-items: center;

    .door-title{
      display: flex;
      align-items: center;
    }
  }
  .door-pin{
    display: flex;
    align-items: center;
  }
}
