$blackColor: #060606;
$lightGrayColor: #808080;
$background-color: #f2f2f2;
$highlight-blue: #008dd0;
$highlight-orange: #ff2100;
$hover-color: #e6e6e6;

// Top-Nav css here
.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  .navigation-title-select {
    -webkit-appearance: none;
    width: 100%;
    height: 38px;
    -moz-appearance: none;
    background: transparent url("data:image/svg+xml;utf8,<svg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.6001 1L7.6001 7L13.6001 1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat;
    background-position-x: 100%;
    background-position-y: 16px;
    margin-right: 2rem;
    padding-left: 9px;

    font-family: "Helvetica Now Display Medium", serif;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;

    border: none;

    &:focus-visible {
      outline: none;
    }
  }
}

.text-color-black {
  color: $blackColor;
}

.text-color-light-gray {
  color: $lightGrayColor;
}

.top-nav-action {
  display: flex;
  align-items: flex-end;
  gap: 26px;

  .icon-wrapper {
    position: relative;
  }

  .notification-count {
    position: absolute;
    border-color: red;
    border-radius: 6.5px;
    width: 11px;
    height: 11px;
    top: 0px;
    right: 0px;
    background: red;
  }

  .top-icons {
    margin-left: 24px;
    display: flex;
    gap: 38px;
    margin-bottom: 12px;

    .icon-container {
      height: 26px;
      width: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $background-color;
      }
    }

    .icons {
      height: 26px;
      width: 26px;
      cursor: pointer;
    }
  }
}
.sidebar-wrapper-mb{
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;

  .menu-icon-mb{
    display: none;
    position: absolute;
    top: 10px;
  }
  .sidebar-container-mb{
    display: flex;
    justify-content: center;
  }

  .sidebar-overlay-mb-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    display: flex;
    .sidebar-overlay-left {
      width: 250px;
      height: 100vh;
      background: black;

      .sidebar-overlay-left-wrapper{
        margin-top: 70px;
        padding: 24px;
        .sidebar-overlay-menu-wrapper{
          display: flex;
          gap: 31px;
          align-items: center;
        }

        .sidebar-overlay-main-menu-wrapper{
          margin-top: 59px;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .sidebar-logout-wrapper{
          width: 63px;
          margin-top: 24px;
        }
      }
    }
    .sidebar-overlay-right{
      flex: 1;
      background: rgba(0, 0, 0, 0.56);;
    }
  }
}
.top-nav-right-wrapper{
  display: flex;
  align-items: center;
}
@media screen and (max-width: 555px) {
  .site-container{
    margin-left: 0px!important;
  }
  .sidebar-wrapper-mb{
    padding: 7px 24px 0 24px;
    .sidebar-container-mb{
      margin-left: 40px;
    }
    .menu-icon-mb {
      display: block;
    }
    .menu-icon{
      padding: 12px;
      width: 32px;
      height: 32px;
    }
  }
}

@media screen and (max-width: 796px) {
  .top-nav {
    flex-direction: column;
    align-items: start;
  }
}


