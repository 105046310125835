.phone-input-wrapper {
  height: 48px;
  background-color: #f4f4f4;
  padding: 0px 8px;
  border-radius: 4px;
}

.phone-input-wrapper .PhoneInputInput {
  background-color: #f4f4f4;
  height: 48px;
  border-width: 0px;
}
