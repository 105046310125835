
.login-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E5E5E5;

  .login-box {
    min-width: 524px;
    height: auto;
    padding: 80px;
    background: #FFFFFF;
    box-shadow: 10px 20px 30px rgba(86, 92, 99, 0.05);
    border-radius: 16px;

    .login-container {
      .welcome-title{
        margin-bottom: 16px;
      }
      .logo-wrapper {
        display: flex;

        .logo-image {
          width: 151.94px;
          height: 39px;
        }
      }

      .login-title {
        margin-top: 34px;
        margin-bottom: 38px;
      }

      .login-form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .login-button-wrapper {
        margin-top: 10px;
      }
      .login-note-wrapper{
        margin-top: 25px;
        display: flex;
        justify-content: center;

        .login-note{
          width: 310px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {

  .login-wrapper {
    padding: 20px 12px;
    background-color: #FFFFFF;
    flex: none;
    height: auto;

    .login-box {
      min-width: 364px;
      box-shadow: none;
      padding: 20px;
    }
  }
}


