.common-form-container{
  margin-top: 68px;
  flex:1;
  .common-form-wrapper{
    margin-left: 49px;
    margin-right: 49px;
    .form-title-wrapper{
      display: flex;
      justify-content: space-between;
      .form-title{

      }
      .form-cancel{

      }
    }

  }
}

.form-button-wrapper{
  margin-top: 50px;
  display: flex;
  gap: 10px;
}
