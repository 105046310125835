.guest-item-wrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 8px 4px 8px;
  &:hover {
    background-color: #edeaea;
    border-radius: 8px;
  }

  .guest-item-title-img-wrapper {
    display: flex;
    gap: 24px;

    .guest-item-detail {
      display: flex;
      flex-direction: column;
    }
  }
}
