.right-sidebar-layout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 1;

  // Display blocked for initial state ----------

  .overlay-blank {
    width: inherit;
    height: 100vh;
    background: #797979;
    opacity: 0.65;
  }

  .form-wrapper {
    position: absolute;
    right: 0;
    background: white;
    height: 100vh;
    -webkit-transition: all 5s ease;
    -moz-transition: all 5s ease;
    -ms-transition: all 5s ease;
    transition: all 5s ease;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 483px) {
  .right-sidebar-layout{
    .form-wrapper {
      width: 100% !important;
    }
  }
}
