.guest-profile-wrapper {
  // padding: 30px 24px;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  margin: 0px 24px;
}
.access-list-box {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  //padding-bottom: 300px;
}
.delete-wrapper {
  position: sticky;
  background-color: white;
  bottom: 0;
  padding: 16px;
  margin-top: 400px;
  display: flex;
  justify-content: center;
}
