.building-list-wrapper {
  margin-top: 20px;
  .building-wrapper {
    margin-bottom: 16px;
    .building {
      display: flex;
      justify-content: space-between;

      &:hover {
        cursor: pointer;
      }
      .building-name-image{
        display: flex;
        gap: 16px;
        .image-wrapper {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background: black;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .building-name{
        display: flex;
        align-items: center;
      }
      .building-drop-icon{
        display: flex;
        align-items: center;
      }
    }
  }
}
