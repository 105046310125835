.product-image {
  margin-top: 20px;
}

.sidebar-wrapper {
  font-family: "Helvetica Now Display";
  height: 100vh !important;
  width: 214px;
  background: black;
  padding: 14px 20px;
  overflow-y: auto;
  // scrollbar-width: none;
  position: fixed;
  top: 0;
  z-index: 999;
}

.divider-margin {
  margin-top: 23px;
  margin-bottom: 18px;
}

.user-type {
  font-family: "Helvetica Now Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #808080;
}

.nav-sidebar-wrapper {
  margin-top: 46px;
  margin-bottom: 34px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidenav-item {
  margin-bottom: 16px;
  display: flex;
  gap: 11px;
  align-items: center;
}

.sidenav-link {
  font-family: "Helvetica Now Display";
  padding: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #FFFFFF;
}

.line {
  border-top: 1px solid white;
  margin-bottom: 16px;
}

.nav-action-link {
  font-family: "Helvetica Now Display Medium";
  padding: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #FFFFFF;
  width: auto;
}

.nav-group {
  .nav-img {
    float: right;
  }
}

.nav-link-item {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 555px) {
  .sidebar-wrapper{
    display: none;
  }
}
