.door-access-wrapper {
  .door-access-radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 30px;
  }
}

.guest-button-marg-top {
  margin-top: 28px;
}

.start-end-time-wrapper {
  display: flex;
  gap: 29px;
}

.select-day-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 50px;

  .select-day {
    display: flex;
    gap: 17px;
  }

}
