.react-time-picker {
  display: flex;
  position: relative;
  height: 48px;
  width: 100%;
  margin: 2px 0px 2px 0px;
  background-color: #f6f6f7;
  border-radius: 4px;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-time-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  /* border: thin solid gray; */
  border-color: #f6f6f7;
}

.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2);
  padding: 2 3px;
  box-sizing: content-box;
  flex: 1;
  display: flex;
  align-items: center;
  text-justify: center;
}

.react-time-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
  margin: 0 2px;
}

.react-time-picker__inputGroup__divider {
  z-index: 1;
}

.react-time-picker__inputGroup__leadingZero {
  /* z-index: -1; */
  /* position: absolute; */
  flex: 1;
  text-align: right;
}


.react-time-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 0px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding-left: 8px; */
}

.react-time-picker__inputGroup__hour {
  text-align: center;
  flex: 2;
}

.react-time-picker__inputGroup__minute {
  flex: 2;
}


.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  flex: 1;
  text-align: left;
}

.react-time-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-time-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-time-picker__button:enabled {
  cursor: pointer;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7;
}

.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d;
}

.react-time-picker__button svg {
  display: inherit;
}

.react-time-picker__clock {
  width: 50px;
  height: 520px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
  z-index: 1;
}

.react-time-picker__clock--closed {
  display: none;
}
