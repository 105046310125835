.door-list-main-container{
  .door-list-selection-wrapper{
    .door-list-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      .door-list-selected-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .door-list-unselected-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
