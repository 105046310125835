.text-field-wrapper {
  width: 100%;

  .text-field-title-wrapper {
    height: 20px;
    display: flex;
    justify-content: space-between;
  }

  .text-field-bottom-text-wrapper {
    min-height: 25px;

    .text-icon-wrapper {
      line-height: 16px;
    }
  }
}

.text-area-wrapper {
  width: 100%;
  height: auto;

  .text-field-title-wrapper {
    height: 20px;
    display: flex;
    justify-content: space-between;
  }

  .text-field-bottom-text-wrapper {
    height: 25px;
  }
}
