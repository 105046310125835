.profile-wrapper{
    padding: 30px 24px;
  }
  
  .user-fields{
    display: flex;
    flex-direction: row;
    margin-top: 24px;
  }

  .mailIcon{
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 24px;
    
  }

  .mail-button{
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    
  }