.access-item-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .access-item-container{
    display: flex;
    gap: 24px;

    .access-item-detail-wrapper{
      .access-item-door{
        display: flex;
        gap: 10px;
      }
    }
  }
}
