.guests-list-wrapper{
  padding: 30px 24px;

  .guests-list-container{

    .guests-search-add-wrapper{
      display: flex;
      gap: 18px;
    }
  }
}
