
.guest-profile-detail-wrapper{
  display: flex;
  justify-content: space-between;

  .guest-profile-image-wrapper{
    display: flex;
    gap: 10px;
  }
}
